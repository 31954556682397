import React, { useEffect, useState } from 'react'
import Router, { useRouter } from 'next/router'
import { classnames } from 'begonya/functions'

import { useApp } from '@/core/contexts/app'
import { Row, Column, Container } from '@/components/base/gridview'
import pageService from '@/services/http/wap/page'
import Icon from '@/components/base/icon'
import Anchor from '@/components/base/anchor'
import Image from '@/components/base/image'
import Button from '@/components/base/button'
import { path } from '@/core/utils'

import Navigation from './Navigation'
import { HeaderProps } from './types'
import { useMobile, useTablet } from '@/core/hooks'
import { useWidth } from '@/core/hooks/useWidthResize'

const AsgHeader: React.FunctionComponent<HeaderProps> = ({
  logo,
  navigation,
  isReverse,
}) => {
  const app = useApp()
  const router = useRouter()
  const width = useWidth()
  const template = app.theme.title

  const [panel, setPanel] = useState('')
  const [isScrolled, setIsScrolled] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  useEffect(() => {
    handleScroll()

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = () => {
    const scrollTop = window.scrollY

    setIsScrolled(scrollTop > 200 ? true : false)
  }

  const handleLanguageSelection = (language: string) => {
    pageService
      .findPath(app.environment, { path: app.page.path, language })
      .then((slug) => {
        router.push(path.app(app.environment, slug))
      })
      .catch(() => {
        const homepagePath = app.settings.homepage

        if (homepagePath) {
          pageService
            .findPath(app.environment, { path: homepagePath, language })
            .then((slug) => {
              router.push(path.app(app.environment, slug))
            })
        }
      })
    setIsDropdownOpen(false)
  }

  const openLanguage = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const renderLanguageSelection = () => {
    if (app.languages.length > 1) {
      return (
        <div className="asg-header-language-selection">
          <div className="asg-header-language-selection-current">
            <Button onClick={openLanguage}>
              {app.language.substring(0, 2)}
            </Button>
          </div>
          {isDropdownOpen && (
            <div className="asg-header-language-selection-dropdown">
              <div className="asg-header-language-selection-dropdown-list">
                {app.languages.map((language) => (
                  <li key={language.id}>
                    <Button
                      onClick={() => handleLanguageSelection(language.id)}
                    >
                      {language.title}
                    </Button>
                  </li>
                ))}
              </div>
            </div>
          )}
        </div>
      )
    }
  }

  useEffect(() => {
    const handleRouteChangeComplete = () => {
      document.body.style.overflow = ''
      setPanel('')
    }

    Router.events.on('routeChangeComplete', handleRouteChangeComplete)

    return function cleanup() {
      Router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [])

  return (
    <header
      className={classnames(
        'asg-header',
        panel && 'asg-header--with-panel',
        isReverse && 'header-reverse',
        isScrolled && 'header-scrolled',
        width > 768 && 'fixed'
      )}
    >
      <Container size={template === 'Ana sayfa teması' ? 'extended' : 'wide'}>
        <Row align="center" justify="between" style={{ alignItems: 'center' }}>
          <Column
            sm={{ size: 4 }}
            md={{ size: 4 }}
            lg={{ size: 2 }}
            xl={{ size: 2 }}
            style={{ textAlign: 'left' }}
          >
            <Anchor className="asg-header-logo" href={app.settings.homepage}>
              <Image
                {...(logo?.src ? logo : app.theme.logo)}
                alt={app.page.title}
                height={50}
              />
            </Anchor>
          </Column>
          {width > 768 && (
            <Column className="width-auto">
              <ul className="desktop-menu">
                {navigation?.map((menuItem, index) => (
                  <li
                    key={index}
                    className={classnames(
                      menuItem.className,
                      menuItem.children.length && 'hasSubMenu'
                    )}
                  >
                    {menuItem.children.length ? (
                      <Anchor
                        onClick={(e) => e.preventDefault()}
                        dangerouslySetInnerHTML={{ __html: menuItem.title }}
                      />
                    ) : (
                      <Anchor
                        href={menuItem.href}
                        dangerouslySetInnerHTML={{ __html: menuItem.title }}
                      />
                    )}

                    {menuItem.children.length ? (
                      <ul className="sub-menu">
                        {menuItem.children.map((subItem, subIndex) => (
                          <li key={subIndex}>
                            <Anchor href={subItem.href}>{subItem.title}</Anchor>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <></>
                    )}
                  </li>
                ))}
              </ul>
            </Column>
          )}

          <Column>
            <div className="asg-header-actions">
              {width <= 768 && (
                <button
                  className="asg-header-menu-button"
                  onClick={() => setPanel('menu')}
                >
                  {/* <span className="asg-header-menu-button-label">
                  {app.settings.translations['menu']}
                </span> */}
                  <Icon name="icon-menu" size="medium" />
                </button>
              )}
              {renderLanguageSelection()}
            </div>
          </Column>
        </Row>
      </Container>
      {navigation ? (
        <Navigation
          isOpen={panel === 'menu'}
          data={navigation}
          onClose={() => setPanel('')}
          logo={logo}
          isDropdownOpen={isDropdownOpen}
          openLanguage={openLanguage}
        />
      ) : null}
    </header>
  )
}

export default AsgHeader
