import React, { useState } from 'react'

import { useMobile } from '@/core/hooks'
import { useApp } from '@/core/contexts/app'
import { Row } from '@/components/base/gridview'
import Anchor from '@/components/base/anchor'
import Icon from '@/components/base/icon'
import { NavigationReference } from '@/services/http/wap/types'

import Panel from './Panel'
import { NavigationBreadcrumb, NavigationProps } from './types'
import { classnames } from 'begonya/functions'
import Button from '@/components/base/button'

const Navigation: React.FunctionComponent<NavigationProps> = ({
  data,
  isOpen,
  logo,
  onClose,
  isDropdownOpen,
  openLanguage,
}) => {
  const [showSubMenu, setshowSubMenu] = useState<any>(null)

  return (
    <Panel
      onClose={onClose}
      isOpen={isOpen}
      logo={logo}
      isDropdownOpen={isDropdownOpen}
      openLanguage={openLanguage}
    >
      <div className="navigation">
        <div style={{ position: 'relative', flex: 1, padding: '1rem 0' }}>
          {
            <nav
              className="navigation-menu"
              style={{ left: '0', opacity: 1, paddingTop: 0 }}
            >
              <ul>
                {data?.map((menuItem, index) => (
                  <li
                    key={index}
                    className={classnames(
                      menuItem.className,
                      menuItem.children.length &&
                        showSubMenu === index &&
                        'hasSubMenu'
                    )}
                  >
                    {menuItem.children.length ? (
                      <Button
                        appearance="subtle"
                        onClick={() =>
                          setshowSubMenu(showSubMenu === index ? null : index)
                        }
                      >
                        {menuItem.title}
                      </Button>
                    ) : (
                      <Anchor
                        href={menuItem.href}
                        dangerouslySetInnerHTML={{ __html: menuItem.title }}
                      />
                    )}

                    {showSubMenu === index && menuItem.children.length ? (
                      <ul className="sub-menu">
                        {menuItem.children.map((subItem, subIndex) => (
                          <li key={subIndex}>
                            <Anchor href={subItem.href}>{subItem.title}</Anchor>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <></>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          }
          {/* {renderNavs()} */}
        </div>
      </div>
    </Panel>
  )
}

export default Navigation
